import React, { useContext } from 'react';
import './FooterTop.styles.scss';
// import IconMap from '../../../assets/Icons/icon-map.svg';
// import IconPhone from '../../../assets/Icons/icon-phone.svg';
import IconShield from '../../../assets/Icons/icon-shield.svg';
import LogoObjBranco from '../../../assets/Logo/logo-obj-branco.svg';
import IconFB from '../../../assets/Icons/icon-social-ig.svg';
import IconYT from '../../../assets/Icons/icon-social-yt.svg';
import IconIG from '../../../assets/Icons/icon-social-fb.svg';
import { Link, useNavigate } from 'react-router-dom';
import { AccountContext } from '../../../contexts/AccountContext';

const FooterTop = () => {
	const { isLoggedIn, toggleAccountModal, termsContracts } =
		useContext(AccountContext);

	const navigate = useNavigate();
	const navigateConta = () => {
		navigate('/conta');
	};

	const accountModalHandler = () => {
		if (isLoggedIn === true) {
			navigateConta();
		} else {
			toggleAccountModal();
		}
	};

	return (
		<>
			<div className="footer-top-wrapper">
				<div className="footer-top-container">
					<div className="footer-logo-container">
						<div className="footer-logo-top-container">
							<div className="footer-logo">
								<img src={LogoObjBranco} alt="" />
							</div>
							<div className="footer-socials">
								<a href="https://www.facebook.com/objetivopi" target='_blank' rel="noreferrer">
									<img src={IconFB} alt="" />
								</a>
								<a href="https://www.youtube.com/@ObjetivoTeresina" target='_blank' rel="noreferrer">
									<img src={IconYT} alt="" />
								</a>
								<a href="https://www.instagram.com/objetivopi/" target='_blank' rel="noreferrer">
									<img src={IconIG} alt="" />
								</a>
							</div>
						</div>
						<div className="footer-unidade-container">
							<div className="footer-unidade-row">
								<div className="footer-unidade">
									<h3>Unidade Leste Fundamental e Médio</h3>
									<p>
										Rua das Orquídeas, 988 <br />
										(86) 3215-5032
									</p>
								</div>
								<div className="footer-unidade">
									<h3>Unidade Leste Kids</h3>
									<p>
										Rua das Orquídeas, 191 <br />
										(86) 3215-5034
									</p>
								</div>
								<div className="footer-unidade">
									<h3>Unidade Leste Vestibulares</h3>
									<p>
										Rua das Orquídeas, 830 <br />
										(86) 3025-8551
									</p>
								</div>
							</div>
							<div className="footer-unidade-row">
								<div className="footer-unidade">
									<h3>Unidade Centrol/Sul I</h3>
									<p>
										Rua Firmino Pires, 787 <br />
										(86) 3215-5480
									</p>
								</div>
								<div className="footer-unidade">
									<h3>Unidade Centro Sul II</h3>
									<p>
										Rua Rui Barbosa, 799 <br />
										(86) 3215-5482
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="links-container">
						<h2 className="ul-title">Links Úteis</h2>
						<ul>
							<li style={{ cursor: 'pointer' }} onClick={accountModalHandler}>
								<h3>Minha Conta</h3>
							</li>
							<li>
								<Link to={'/compra-rapida'}>
									<h3>Compra Rápida</h3>
								</Link>
							</li>
							<li>
								<Link to={'/faq'}>
									<h3>F.A.Q.</h3>
								</Link>
							</li>
							<li>
								<Link to={'/atendimento'}>
									<h3>Atendimento</h3>
								</Link>
							</li>
							<li>
								<a
									target="_blank"
									rel="noopener noreferrer"
									href={termsContracts}>
									<h3>Termos & Condições</h3>
								</a>
							</li>


						</ul>
						<div className="segurança-container">
							<img src={IconShield} alt="mapa" />
							<div className="segurança-text">
								<h3>100% Seguro</h3>
								<p>Suas informações estão criptografadas e seguras.</p>
							</div>
						</div>
					</div>
					{/* <div className="info-container">
						<h2 className="ul-title">Informações da Loja</h2>
						<ul>
							<li>
								<div className="endereço-container">
									<img src={IconMap} alt="mapa" />
									<h3>
										Av. N. Sra. de Fátima, 1000 <br />
										Jóquei - Teresina - PI.
									</h3>
								</div>
							</li>
							<li>
								<div className="telefone-container">
									<img src={IconPhone} alt="mapa" />
									<h3>(86) 2222-4000</h3>
								</div>
							</li>
							<li>
								<div className="segurança-container">
									<img src={IconShield} alt="mapa" />
									<div className="segurança-text">
										<h3>100% Seguro</h3>
										<p>Suas informações estão criptografadas e seguras.</p>
									</div>
								</div>
							</li>
						</ul>
					</div> */}
				</div>
			</div>
		</>
	);
};

export default FooterTop;
