import React, { useContext, useState, useEffect } from 'react';
import CartIcon from '../Cart/CartIcon/CartIcon';
import CartDropdown from '../Cart/CartDropdown/CartDropdown';
import { useLocation, Link, useNavigate } from 'react-router-dom';

import { CartContext } from '../../contexts/CartContext';
import { CategoryContext } from '../../contexts/CategoryContext';

import LogoObj from '../../assets/Logo/logomarca-obj.png';
import Search from './Search';
import { AccountContext } from '../../contexts/AccountContext';

const Header = () => {
	const { isCartOpen } = useContext(CartContext);
	const [open, setOpen] = useState(false);
	const { categories, categoryIcons } = useContext(CategoryContext);
	const { isLoggedIn, toggleAccountModal, loggedUserData, logoutHandler } =
		useContext(AccountContext);

	const name = loggedUserData?.customer.name;
	const email = loggedUserData?.customer.email;

	const firstName = name?.split(' ')[0];

	const location = useLocation();
	const navigate = useNavigate();
	const navigateConta = () => {
		navigate('/conta');
	};

	const accountModalHandler = () => {
		if (isLoggedIn === true) {
			navigateConta();
		} else {
			toggleAccountModal();
		}
	};

	const handleOpen = () => {
		setOpen(!open);
	};

	const handleClose = () => {
		setOpen(false)
	}

	const [top, setTop] = useState(true);

	useEffect(() => {
		const scrollHandler = () => {
			window.scrollY > 10 ? setTop(false) : setTop(true)
		};
		window.addEventListener('scroll', scrollHandler);
		return () => window.removeEventListener('scroll', scrollHandler);
	}, [top]);

	return (
		<nav className={`header ${location.pathname !== '/' || !top ? 'box-shadow' : ''}`} style={{ display: 'flex', justifyContent: 'center' }}>
			<div className={'header-content'}>
				<div className="logo-container">
					<Link to="/">
						<img src={LogoObj} alt="" className="logo" />
					</Link>
				</div>
				<div className="categoria-btn-container">
					<div className="dropdown">
						<span className="shown">
							<button className="categoria-btn">
								<span className="material-symbols-outlined">widgets</span>
								<p>Categorias</p>
								<span id="yellow-arrow" className="material-symbols-outlined">
									expand_more
								</span>
							</button>
						</span>

						<div className="dropdown-content">
							{categories.map((categoryItem, index) => (
								<Link
									key={index}
									id="link"
									to={`/categoria/${categoryItem.slug}`}>
									<div className="filtro-nav-container">
										<div className="filtro-nav-icon">
											<span className="material-symbols-outlined">
												{categoryIcons[categoryItem.slug] ??
													categoryIcons['outros']}
											</span>
										</div>
										<div className="filtro-nav-text">{categoryItem.title}</div>
									</div>
								</Link>
							))}
						</div>
					</div>
				</div>
				<div className="search-container">
					<Search />
				</div>
				<div className="account-cart-container">
					<div className="account-container">
						{isLoggedIn === false && (
							<button
								className="account-logged-out"
								title='Fazer Login ou Registrar'
								onClick={accountModalHandler}>
								<span className="material-symbols-outlined">person</span>
							</button>
						)}
						{isLoggedIn === true && (
							<button className="account-btn" onClick={handleOpen}  title='Opções de Conta'>
								{loggedUserData?.customer?.avatar !== '' ? (
									<img src={loggedUserData?.customer?.avatar} alt="" />
								) : (
									<span className="material-symbols-outlined">person</span>
								)}
							</button>
						)}
						{isLoggedIn === true && open === true && (
							<div className="account-dropdown">
								<ul className="account-menu">
									<li className="conta">Olá, {firstName}</li>
									<li className="email">{email}</li>
									<hr className="account-menu-hr" />
									<Link to={'/conta'} onClick={handleClose}>
										<li className="account-menu-item">
											<span
												id="minha-conta-icon"
												className="material-symbols-outlined">
												person
											</span>
											Minha Conta
										</li>
									</Link>
									<Link to={'/compra-rapida'} onClick={handleClose} title="Compra Rápida">
										<li className="account-menu-item">
											<span
												id="compra-rapida-icon"
												className="material-symbols-outlined">
												shopping_cart_checkout
											</span>
											Compra Rápida
										</li>
									</Link>
									<Link to={'/atendimento'} onClick={handleClose}>
										<li className="account-menu-item">
											<span
												id="atendimento-icon"
												className="material-symbols-outlined">
												support_agent
											</span>
											Atendimento
										</li>
									</Link>
									<hr className="account-menu-hr" />
									<li
										className="account-menu-item sair"
										onClick={() => logoutHandler()}>
										<span id="sair-icon" className="material-symbols-outlined">
											logout
										</span>
										Sair
									</li>
								</ul>
							</div>
						)}
					</div>
					<div>
						{location.pathname !== '/' ? (
							<Link to="/compra-rapida" className='compra-rapida-header' title='Compra Rápida'>
								<span className="material-symbols-outlined">
									shopping_cart_checkout
								</span>
							</Link>
						) : ''}
					</div>
					<div className="cart-container" title='Carrinho'>
						<CartIcon />
					</div>
				</div>
				{isCartOpen && <CartDropdown />}
			</div>
		</nav>
	);
};

export default Header;
