import React from 'react';
import { useNavigate } from 'react-router-dom';

const NavMenu = () => {
	const navigate = useNavigate();
	const navigateCompraRapida = () => {
		navigate('/compra-rapida');
	};

	return (
		<div className="navmenu">
			<a href="#lançamentos">Lançamentos</a>
			{/* <span class="separator material-symbols-outlined">
				fiber_manual_record
			</span> */}
			{/* <a href="#cursos">Cursos</a> */}
			{/* <span class="separator material-symbols-outlined">
				fiber_manual_record
			</span> */}
			<a href="#fardamento">Fardamentos</a>
			{/* <span class="separator material-symbols-outlined">
				fiber_manual_record
			</span> */}
			<a href="#material">Material</a>
			{/* <span class="separator material-symbols-outlined">
				fiber_manual_record
			</span> */}
			<a href="#papelaria">Papelaria</a>
			{/* <span class="separator material-symbols-outlined">
				fiber_manual_record
			</span> */}
			<button className="compra-rapida-btn" onClick={navigateCompraRapida}>
				<span className="material-symbols-outlined">
					shopping_cart_checkout
				</span>
				<p>Compra Rápida</p>
			</button>
		</div>
	);
};

export default NavMenu;
