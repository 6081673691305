import { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { compare } from '../utils/compare';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const STORE_ID = process.env.REACT_APP_STORE_ID;

// const options = {
// 	method: 'GET',
// 	headers: { 'Content-Type': 'application/json' },
// 	body: '{"filters":{"page":"","limit":"","sort":"","order":"asc","field":"CODPERLET","filter":"202200"}}',
// };

export const SeriesContext = createContext(null);

export const SeriesProvider = ({ children }) => {
	// Series LIST STATE
	const [series, setSeries] = useState([]);

	const [selectedFilters, setSelectedFilters] = useState([]);

	// FETCH Series LIST FROM API
	const options = {
		method: 'GET',
		url: `${BASE_URL}/serie-totvs/list-serie-totvs-by-store-id/${STORE_ID}`,
		headers: { 'Content-Type': 'application/json' },
		params: {
			CODPERLET: new Date().getFullYear() + "00",
		},
	};

	useEffect(() => {
		axios
			.request(options)
			.then(function (response) {
				setSeries(response.data.series);
			})
			.catch(function (error) {
				console.error(error);
			});
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// SEPARATE SERIES WITH FILTER
	const seriesReduce = series.reduce((acc, item) => {
		if (!acc[item.CURSO]) acc[item.CURSO] = [];
		acc[item.CURSO].push(item);
		return acc;
	}, {});

	const allowedCourses = ['Ensino Médio', 'Ensino Fundamental', 'Pré Vestibular', 'Educação Infantil']

	const filteredSeries = Object.keys(seriesReduce).filter(serie => allowedCourses.includes(serie)).reduce((serie, key) => {
		serie[key] = seriesReduce[key];
    return serie;
	}, {})

	const seriesArray = Object.keys(filteredSeries).map((serie) => [
		serie,
		seriesReduce[serie],
	]);

	// SERIE MEDIO
	const SerieMedio = series.filter((serie) =>
		serie.CURSO.trim()
			.toLowerCase()
			.normalize('NFD')
			.replace(/\p{Diacritic}/gu, '')
			.includes('medio')
	).sort(compare);

	// SERIE FUNDAMENTAL
	const SerieFundamental = series.filter((serie) =>
		serie.CURSO.trim()
			.toLowerCase()
			.normalize('NFD')
			.replace(/\p{Diacritic}/gu, '')
			.includes('fundamental')
	).sort(compare);

	// SERIE FUNDAMENTAL INICIO
	// const SerieFundamentalInicio = series.filter((serie) =>
	// 	serie.CURSO.trim()
	// 		.toLowerCase()
	// 		.normalize('NFD')
	// 		.replace(/\p{Diacritic}/gu, '')
	// 		.includes('lementary')
	// );

	// SERIE INFANTIL
	const SerieInfantil = series.filter((serie) =>
		serie.CURSO.trim()
			.toLowerCase()
			.normalize('NFD')
			.replace(/\p{Diacritic}/gu, '')
			.includes('infantil')
	).sort(compare);

	// PRÉ-VESTIBULAR
	const SeriePreVestibular = series.filter((serie) =>
		serie.CURSO.trim()
			.toLowerCase()
			.normalize('NFD')
			.replace(/\p{Diacritic}/gu, '')
			.includes('vestibular')
	).sort(compare);

	// CURSOS EXTRAS
	// const SerieExtras = series.filter((serie) =>
	// 	serie.CURSO.trim()
	// 		.toLowerCase()
	// 		.normalize('NFD')
	// 		.replace(/\p{Diacritic}/gu, '')
	// 		.includes('extra')
	// );



	// SELECTED FILTER CHECKBOX HANDLER
	function checkboxHandler(e, codgrade, FiltroNome, serieID) {
		var value = {
			habilitacao: e.target.value,
			codgrade: codgrade,
			serieParent: FiltroNome,
			id: serieID,
		};
		let updatedList = [...selectedFilters];
		if (e.target.checked) {
			// setSelectedFilters((values) => [...values, value]);
			updatedList = [...selectedFilters, value];
		} else {
			// setSelectedFilters((values) => values.filter((e) => e !== value));
			updatedList = selectedFilters.filter(x => x.id !== value.id);
		}
		setSelectedFilters(updatedList);
	}

	// MAPPED ARRAY FOR COMBOBOX - SERIE_PARENT, SERIE_CHILDREN
	const mappedPreVestibular = SeriePreVestibular.map((serie) => ({
		serie_parent: 'Pré-Vestibular',
		serie_children: serie.HABILITACAO.trim()
			.toLowerCase()
			.normalize('NFD')
			.replace(/\p{Diacritic}/gu, ''),
		serie_id: serie.id,
	}));

	const mappedMedio = SerieMedio.map((serie) => ({
		serie_parent: 'Ensino Médio',
		serie_children: serie.HABILITACAO.trim()
			.toLowerCase()
			.normalize('NFD')
			.replace(/\p{Diacritic}/gu, ''),
		serie_id: serie.id,
	}));

	const mappedFundamental = SerieFundamental.map((serie) => ({
		serie_parent: 'Ensino Fundamental',
		serie_children: serie.HABILITACAO.trim()
			.toLowerCase()
			.normalize('NFD')
			.replace(/\p{Diacritic}/gu, ''),
		serie_id: serie.id,
	}));

	const mappedInfantil = SerieInfantil.map((serie) => ({
		serie_parent: 'Educação Infantil',
		serie_children: serie.HABILITACAO.trim()
			.toLowerCase()
			.normalize('NFD')
			.replace(/\p{Diacritic}/gu, ''),
		serie_id: serie.id,
	}));

	// const mappedFundamentalInicio = SerieFundamentalInicio.map((serie) => ({
	// 	serie_parent: 'Elementary School / Ensino Fundamental I',
	// 	serie_children: serie.HABILITACAO.trim()
	// 		.toLowerCase()
	// 		.normalize('NFD')
	// 		.replace(/\p{Diacritic}/gu, ''),
	// 	serie_id: serie.id,
	// }));

	// const mappedExtra = SerieExtras.map((serie) => ({
	// 	serie_parent: 'Cursos Extras',
	// 	serie_children: serie.HABILITACAO.trim()
	// 		.toLowerCase()
	// 		.normalize('NFD')
	// 		.replace(/\p{Diacritic}/gu, ''),
	// 	serie_id: serie.id,
	// }));

	let comboboxSeriesList = [
		...mappedPreVestibular,
		...mappedMedio,
		...mappedFundamental,
		//...mappedFundamentalInicio,
		...mappedInfantil,
		//...mappedExtra,
	];

	// PROVIDED VALUES
	const value = {
		seriesArray,
		series,
		SerieMedio,
		SerieFundamental,
		// SerieFundamentalInicio,
		SerieInfantil,
		SeriePreVestibular,
		selectedFilters,
		setSelectedFilters,
		checkboxHandler,
		comboboxSeriesList,
	};
	return (
		<SeriesContext.Provider value={value}>{children}</SeriesContext.Provider>
	);
};
