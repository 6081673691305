function compare( a, b ) {
    if ( a.HABILITACAO < b.HABILITACAO ){
      return -1;
    }
    if ( a.HABILITACAO > b.HABILITACAO ){
      return 1;
    }
    return 0;
  }

export { compare };